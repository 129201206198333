import { yupResolver } from '@hookform/resolvers/yup';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Alert,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  Controller, useFieldArray,
  useForm,
  useWatch
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import ContentPageLoader from '../../../components/Loader/ContentPageLoader';
import {
  useCreateQuotationMutation,
  useGetAircraftListQuery,
  useGetAircraftTypesListQuery,
  useGetAirportListQuery,
  useGetCargoListQuery,
  useGetCompaniesListQuery,
  useGetFdpMutation,
  useGetPersonsListQuery,
  useGetQuotationMutation,
  useUpdateQuotationMutation,
} from '../../../store/session';
import { getPrice, getPriceOld } from '../../../utils/fdp';
import { useMenuToggle } from '../../../utils/hooks/useMenuToggle';
import ActionDropDown from './ActionDropDown';
import CharterDrawer from './CharterDrawer';
import ConditionsForm from './form/ConditionsForm';
import CreateQuotationForm from './form/CreateQuotationForm';
import FdpForm from './form/FdpForm';
import FleetFormHeader from './form/FleetFormHeader';
import HopsForm from './form/HopsForm';
import { defaultValues, schema } from './schema';
import { updateBtn } from './styles';
import { round } from '../../../utils/number';

const CreateQuotation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [fdp, { data: fdpResult, isLoading: isFdpLoading }] = useGetFdpMutation();
  const { data: AirportList, isLoading: airportListLoad, refetch: refetchAirportList } = useGetAirportListQuery({ with_fuel_price: true }, {
    refetchOnMountOrArgChange: true
  });
  const { data: AircraftList, isLoading: aircraftListLoad } = useGetAircraftListQuery();
  const { data: AircraftTypesList, isLoading: aircraftTypesListLoad } = useGetAircraftTypesListQuery();
  const { data: CompaniesList, isLoading: companiesListLoad } = useGetCompaniesListQuery();
  const { data: CargoList, isLoading: cargoListLoad } = useGetCargoListQuery();
  const [isOpenCharterDrawer, setOpenCharterDrawer] = useState(false);
  const [getQuotation] = useGetQuotationMutation();
  const [createQuotation] = useCreateQuotationMutation();
  const [updateQuotation] = useUpdateQuotationMutation();
  const [resetAirport, setResetAirport] = useState(false);
  const [company, setCompany] = useState(null);
  const [person, setPerson] = useState(null);
  const [price, setPrice] = useState('-');
  const [oldPrice, setOldPrice] = useState('-');
  const [priceLabel, setPriceLabel] = useState('');
  const [formPrefill, setFormPrefill] = useState(true);
  const [submitButton, setSubmitButton] = useState(false);

  const isLoading = airportListLoad || aircraftListLoad || companiesListLoad || aircraftTypesListLoad || cargoListLoad;

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues
  });

  const {
    fields: hopFields,
    append: hopAppend,
    remove: hopRemove,
    replace: hopUpdate,
  } = useFieldArray({
    name: 'hops',
    control,
  });

  const hopsWatcher = useWatch({
    name: 'hops',
    control
  });

  const hopRepeaterProps = {
    hopFields,
    hopAppend,
    hopRemove,
    hopUpdate,
  };

  const {
    open: openAction, openMenu: openMenuAction, closeMenu: closeMenuAction, anchorEl: anchorElAction,
  } = useMenuToggle();

  const formProps = {
    setValue, control, Controller, errors, trigger, getValues, watch,
  };

  const handleOpenCharterDrawer = () => {
    setOpenCharterDrawer(true);
  };

  const handleCloseCharterDrawer = () => {
    setOpenCharterDrawer(false);
  };

  const handleSetNewestHops = (vlv) => {
    setResetAirport(vlv);
  };

  const setQuotation = async (fleetData = null) => {
    let data;
    if (!fleetData) {
      if (!id) {
        return;
      }
      const { data: requestData } = await getQuotation(id);
      data = requestData;
    } else {
      data = fleetData;
    }

    if (data && !isLoading) {
      const currentCompany = CompaniesList.find(i => i.id === data.fleet_company);
      setCompany({ value: currentCompany.id, label: currentCompany.company_name, email: currentCompany.company_email });
      setValue('hops', data.hops);
      setValue('fleet_company', data.fleet_company);
      setValue('fleet_company_person', data.fleet_company_person || '');
      setValue('fleet_typeofac', data.fleet_typeofac);
      setValue('fleet_regofac', data.fleet_regofac);
      setValue('fleet_cargo', data.fleet_cargo);
      setValue('fleet_payload', data.fleet_payload);
      setValue('fleet_dimensions', data.fleet_dimensions);
      setValue('fleet_flttime', data.fleet_flttime);
      setValue('fleet_fdp', data.fleet_fdp);
      setValue('fleet_date', moment(data.fleet_date).toDate());
      setValue('fleet_crew_ready', data.fleet_crew_ready ? moment(data.fleet_crew_ready).toDate() : '');
      setValue('fleet_excluding', data.fleet_excluding);
      setValue('fleet_subjectto', data.fleet_subjectto);
      setValue('fleet_acmi', data.fleet_acmi);
      setValue('fleet_cond_nav', data.fleet_cond_nav);
      setValue('total_fuel_price', data.total_fuel_price);
      setValue('fleet_cond_other', data.fleet_cond_other); // Other
      setValue('fleet_price', data.fleet_price);
      setValue('sector', data?.sector);
      setValue('company_name', data?.company_name);
      setValue('created_by', data?.created_by);
      setValue('updated_by', data?.updated_by);
      setValue('created_at', data?.created_at);
      setValue('updated_at', data?.updated_at);
      setValue('fleet_cond_fuel', data?.fleet_cond_fuel);
      setFormPrefill(false);
    }
  };

  const { data: PersonsList, isLoading: personsListLoad } = useGetPersonsListQuery(watch('fleet_company'), {
    skip: !watch('fleet_company')
  });

  const onSubmit = async (data) => {
    setSubmitButton(true);
    const toastId = toast.loading('Loading...');
    const quotationParams = {
      ...data,
      fleet_flttime: moment().format('HH:mm'),
      fleet_crew_ready: moment(data.fleet_crew_ready).format('YYYY-MM-DD HH:mm'),
      fleet_date: moment(data.fleet_date).format('YYYY-MM-DD HH:mm'),
    };

    if (id) {
      const personId = watch('fleet_company_person');
      if (!PersonsList.find((personItem) => personItem.id === personId)) {
        quotationParams.fleet_company_person = null;
      }
      await updateQuotation({ data: quotationParams, id }).then((res) => {
        if (!res?.error) {
          toast.success('Quotation updated!', {
            id: toastId
          });
          setQuotation(res.data);
          navigate(`/quotations/update/${id}`);
        } else {
          toast.error('Something went wrong.', {
            id: toastId
          });
        }
        setSubmitButton(false);
      });

      return;
    }

    await createQuotation(quotationParams).then((res) => {
      if (!res?.error) {
        toast.success('Quotation created!', {
          id: toastId
        });
        navigate(`/quotations/update/${res?.data.id}`);
      } else {
        toast.error('Something went wrong.', {
          id: toastId
        });
      }
      setSubmitButton(false);
    });
  };

  const handleGetPrice = () => {
    const formValues = getValues();
    const legsCount = formValues.hops.length;

    const conditions = {
      acmi: +formValues.fleet_acmi,
      nav: +formValues.fleet_cond_nav,
      other: +formValues.fleet_cond_other,
      fuel: +formValues.fleet_cond_fuel,
      legsCount,
    };

    let sumFees = 0;
    let sumDist = 0;
    let sumTime = 0;
    let sumFuelPrice = 0;
    let sumQkg = 0;

    formValues.hops.forEach((hop, index) => {
      let fees = hop.hop_ap_fees_t ? +hop.hop_ap_fees_t : 0;
      if (!index) {
        if (hop.hop_ap_fees_f && hop.hop_ap_fees_t) {
          fees = +hop.hop_ap_fees_f + +hop.hop_ap_fees_t;
        } else if (hop.hop_ap_fees_f) {
          fees = +hop.hop_ap_fees_f;
        } else if (hop.hop_ap_fees_t) {
          fees = +hop.hop_ap_fees_t;
        }
      }

      if (hop.hop_flttime) {
        const ftm = hop.hop_flttime.split(':');

        sumTime += parseFloat(ftm[0]) + parseFloat(ftm[1] / 60);
        sumFees += fees;
        sumDist += +hop.hop_distance;
        sumFuelPrice += +hop.leg_fuel_price;
        sumQkg += +hop.hop_q_kg;
      }
    });

    setValue('total_fuel_price', parseFloat(sumFuelPrice.toFixed(4)));
    const currPrice = getPrice(conditions, sumFees, sumDist, sumTime, sumFuelPrice);
    const oldPrice = round(getPriceOld(conditions, sumFees, sumDist, sumTime, sumQkg));

    if (!currPrice) {
      setValue('fleet_price', '-');
      setPrice('-');
      setPriceLabel('');

      return;
    }

    setValue('fleet_price', currPrice);
    setPrice(currPrice);
    setOldPrice(oldPrice);
    setPriceLabel(`Flight Price(${(sumTime * conditions.acmi).toFixed(2)}) + Navigation fee(${sumDist * conditions.nav}) + Fuel Price(${sumFuelPrice.toFixed(2)}) + sumFees(${sumFees}) + Profit(${conditions.other})`);
  };

  const handleSetPerson = (value) => {
    if (value === person?.id) {
      return;
    }

    if (value) {
      const currentPerson = PersonsList.find(i => i.id === value);
      if (currentPerson) {
        setPerson({ value: currentPerson.id, label: currentPerson.person_name, email: currentPerson.person_email });
      }
    }
  };

  useEffect(() => {
    const personId = watch('fleet_company_person');
    if (id && !personsListLoad && personId) {
      handleSetPerson(personId);
    }
  }, [id, personsListLoad]);

  useEffect(() => {
    if (isValid) {
      handleGetPrice();
    }
  }, [isValid]);

  useEffect(() => {
    if (hopsWatcher) {
      const getFdpParams = [];

      hopsWatcher.forEach((hop) => {
        if (hop.hop_start_id && hop.hop_end_id) {
          getFdpParams.push({
            'pl_hop_from': hop.hop_start,
            'pl_hop_from_id': hop.hop_start_id,
            'pl_hop_departure': moment(hop.hop_departure).format('YYYY-MM-DD HH:mm'),
            'pl_hop_arriving': moment(hop.hop_arriving).format('YYYY-MM-DD HH:mm'),
            'pl_hop_to': hop.hop_end,
            'pl_hop_to_id': hop.hop_end_id,
            'pl_hop_blockoff': null,
            'pl_hop_blockon': null
          });
        }
      });

      fdp(getFdpParams);
    }
  }, [hopsWatcher]);

  useEffect(() => {
    if (id && !isLoading) {
      setQuotation();
    }
    if (!id) {
      setFormPrefill(false);
    }
  }, [id, isLoading]);

  if (isLoading || formPrefill) {
    return <ContentPageLoader />;
  }

  const watchHops = watch('hops')?.filter(i => i.hop_empty === 0 || i.hop_empty === false);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4} rowSpacing={2}>
        <Grid item xs={12}>
          <FleetFormHeader getValues={getValues} id={id} />
        </Grid>
        <Grid item xs={12} sm={8}>
          <CreateQuotationForm
            company={company}
            setCompany={setCompany}
            person={person}
            handleSetPerson={handleSetPerson}
            CompaniesList={CompaniesList}
            companiesListLoad={companiesListLoad}
            PersonsList={PersonsList}
            personsListLoad={personsListLoad}
            AircraftTypesList={AircraftTypesList}
            aircraftTypesListLoad={aircraftTypesListLoad}
            isUpdate={id}
            CargoList={CargoList}
            cargoListLoad={cargoListLoad}
            AirportList={AirportList}
            {...formProps}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ flexGrow: 1 }}>
          <ConditionsForm props={formProps} price={price} priceLabel={priceLabel} oldPrice={oldPrice} />
          <FdpForm isFdpLoading={isFdpLoading} fdpResult={fdpResult} />
        </Grid>
        <Grid item xs={12}>
          <Divider />

          {fdpResult?.some(i => i.currentFdp > i.maxFdp) && (
            <Stack sx={{ width: '100%' }} spacing={2}>
              <Alert severity="error">CREW REST NEEDED!</Alert>
            </Stack>
          )}

          <Card sx={{ mt: '1.25rem' }}>
            <TabContext value="1">
              <CardContent sx={{ padding: '0rem' }}>
                <TabPanel value="1" sx={{ padding: '0rem' }}>
                  <HopsForm
                    {...formProps}
                    {...hopRepeaterProps}
                    refetchAirportList={refetchAirportList}
                    setQuotation={setQuotation}
                    onGetPrice={handleGetPrice}
                    getFdp={fdp}
                    isUpdate={id}
                    hopRemove={hopRemove}
                    airportListLoad={airportListLoad}
                    AircraftList={AircraftList}
                    AirportList={AirportList}
                    AircraftTypesList={AircraftTypesList}
                    resetAirport={resetAirport}
                    handleSetNewestHops={handleSetNewestHops}
                  />
                </TabPanel>
              </CardContent>
            </TabContext>
          </Card>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', gap: '10px' }}>
          <Button type="submit" variant="contained" disabled={submitButton} size="large">{id ? 'Update' : 'Save' }</Button>

          {id && (
            <>
              <Button sx={updateBtn} size="large" onClick={handleOpenCharterDrawer}>
                Send Quotation
              </Button>

              <Button sx={updateBtn} size="large" onClick={openMenuAction}>
                Action
              </Button>

              <ActionDropDown
                id={id}
                open={openAction}
                onClose={closeMenuAction}
                anchorEl={anchorElAction}
                handleSetNewestHops={handleSetNewestHops}
              />

              <CharterDrawer
                open={isOpenCharterDrawer}
                onClose={handleCloseCharterDrawer}
                CompaniesList={CompaniesList}
                PersonsList={PersonsList}
                AircraftTypesList={AircraftTypesList}
                AircraftList={AircraftList}
                CargoList={CargoList}
                values={watch}
                hops={watchHops}
                date={watch('fleet_date')}
              />
            </>
          )}
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateQuotation;
