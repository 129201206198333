import {
  Box, Card, Grid, Divider, Button, Typography
} from '@mui/material';
import React, { useState } from 'react';
import { Filters, GreenButton } from '../../../../../Logistics/style';
import DeclineButton from '../DeclineButton';
import DatePicker from '../../../../../../form/components/DatePicker';
import { useApprovePaymentMutation } from '../../../../../../store/slices/invoiceManagementSlice';
import toast from 'react-hot-toast';
import Upload from '../../../../../../components/Upload';
import { setDateValue, validateDatePickerValue } from '../../../../../../utils/setDateValue';
import { file, fileName, label } from '../../../../../Logistics/OrdersDashboard/components/forms/approved/invoices/style';
import FormGroup from '../../../../../../form/components/FormGroup';
import { Controller, useForm } from 'react-hook-form';
import FieldError from '../../../../../../form/components/FieldError';
import { defaultValues, schema } from './pendingPaymentSchema';
import { yupResolver } from '@hookform/resolvers/yup';

const PendingPaymentForm = ({ invoiceId, onSubmit }) => {
  const [approveInvoice, { isLoading }] = useApprovePaymentMutation();
  const [nameFile, setNameFile] = useState();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const uploadFile = (e) => {
    setNameFile(e.target.files[0].name);
    setValue('proof_of_payment_file', e.target.files[0]);
  };
  const onSubmitForm = async (data) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    formData.append('proof_of_payment_file', data.proof_of_payment_file);
    formData.append('payment_date', data.payment_date);

    const { error } = await approveInvoice({ id: invoiceId, data: formData });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId
      });

      return;
    }

    toast.success('Successfully!', {
      id: toastId
    });
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Card sx={{ padding: '1rem 1rem 1rem 1rem' }}>
        <Grid container>
          <Grid item xs={12} sx={file}>
            <FormGroup sx={label} required label="Proof of payment" hasError={!!errors.proof_of_payment_file}>
              <Controller
                name="proof_of_payment_file"
                control={control}
                render={({ field }) => (
                  <Upload
                    {...field}
                    accept="application/pdf"
                    handleUpload={(e) => {
                      field.onChange(e);
                      uploadFile(e);
                    }}
                    title="Upload file"
                  />
                )}
              />
              <FieldError error={errors.proof_of_payment_file} />
            </FormGroup>
            <Typography sx={fileName}>{nameFile}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup sx={label} label="Payment date" required hasError={!!errors.payment_date}>
              <Controller
                name="payment_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    placeholderText="YYYY-MM-DD"
                    dateFormat="yyyy-MM-dd"
                    showMonthDropdown
                    showYearDropdown
                    onChange={(e) => {
                      field.onChange(e);
                      setDateValue(e, setValue, 'payment_date', 'YYYY-MM-DD');
                    }}
                    value={validateDatePickerValue(field.value)}
                  />
                )}
              />
              <FieldError error={errors.payment_date} />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sx={{ minHeight: '500px' }}>
            <Divider />
            <Box sx={{ ...(Filters), padding: '0px', justifyContent: 'space-between' }}>
              <Button sx={GreenButton} variant="contained" type="submit" disabled={isLoading}>Payed</Button>
              <DeclineButton invoiceId={invoiceId} onSubmit={onSubmit} />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </form>
  );
};

export default PendingPaymentForm;
