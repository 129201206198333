import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import Button from '../../../../components/Button';
import Drawer from '../../../../components/Drawer';
import Upload from '../../../../components/Upload';
import FormGroup from '../../../../form/components/FormGroup';
import Select from '../../../../form/components/Select';
import { TextWrap } from '../../../Logistics/style';
import { useImportFuelPrices } from './useImportFuelPrices';

const ImportFuelPrices = (props) => {
  const {
    open = false,
    onClose = () => {},
  } = props;

  const {
    methods,
    onSubmit,
    formattedSupplierList,
    isLoadingFuelFile,
    supplierListLoad,
    fileName,
    setFileName
  } = useImportFuelPrices({ onClose });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    reset
  } = methods;

  useEffect(() => {
    if (open) {
      reset();
      setFileName('');
    }
  }, [open]);

  return (
    <Drawer
      title="Import Fuel Prices"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: 'auto',
        },
      }}
    >
      <FormProvider {...methods}>
        <form>
          <Grid container spacing={2} sx={{ padding: '24px', margin: '0' }}>
            <Grid item xs={12}>
              <FormGroup label="Supplier" required hasError={!!errors.supplier_id}>
                <Controller
                  name="supplier_id"
                  control={control}
                  render={({ field: { onChange, ...req } }) => (
                    <Select
                      options={formattedSupplierList}
                      placeholder="Supplier"
                      {...req}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      disabled={supplierListLoad}
                    />
                  )}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <FormGroup label="File" required hasError={!!errors.upload_file}>
                <Controller
                  name="upload_file"
                  control={control}
                  render={() => (
                    <Upload
                      buttonSx={{ height: '100%' }}
                      disabled={isLoadingFuelFile}
                      handleUpload={(e) => {
                        if (!e.target.files[0]) {
                          setValue('upload_file', null);
                          setFileName('');
                        }
     
                        setValue('upload_file', e.target.files[0]);
                        setFileName(e.target.files[0]?.name || '');
                      }}
                      accept=".csv"
                      title="Import Fuel Prices"
                    />
                    
                  )}
                />
              </FormGroup>
              <Typography sx={{ fontStyle: 'italic', ...(TextWrap) }}>{fileName}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Button title="Send" onClick={handleSubmit(onSubmit)} />
            </Grid>
          </Grid>
          
        </form>
      </FormProvider>
      
    </Drawer>
  );
};

export default ImportFuelPrices;