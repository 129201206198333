import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useGetFuelSuppliersListQuery, useImportFuelPricesMutation } from '../../../../store/session';
import { selectOptionFormat } from '../../../../utils/number';
import { defaultValues, schema } from './schema';

export const useImportFuelPrices = ({ onClose }) => {
  const [uploadFuelFile, { isLoading: isLoadingFuelFile }] = useImportFuelPricesMutation();
  const { data: SupplierList, isLoading: supplierListLoad } = useGetFuelSuppliersListQuery();

  const [fileName, setFileName] = useState('');

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const formattedSupplierList = selectOptionFormat(SupplierList, 'fuel_suplier');

  const onSubmit = async (data) => {
    const toastId = toast.loading('Loading...');
    const formData = new FormData();

    formData.append('upload_file', data?.upload_file);
    formData.append('supplier_id', data?.supplier_id);

    const res = await uploadFuelFile(formData);

    if (res?.error) {
      toast.error(res?.error?.message || 'Something went wrong.', {
        id: toastId,
      });

      return;
    }

    methods.reset();
    setFileName('');
    onClose();

    toast.success('Success!', {
      id: toastId,
    });
  };

  return {
    methods,
    onSubmit,
    formattedSupplierList,
    isLoadingFuelFile,
    supplierListLoad,
    fileName,
    setFileName
  };
};