import React from 'react';
import { Card } from '@mui/material';

const PrepaymentForm = () => (
  <Card sx={{ padding: '1rem 1rem 1rem 1rem' }}>
    Proforma payed. Awaiting Invoice.
  </Card>
);

export default PrepaymentForm;
