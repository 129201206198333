import {
  Box, Card, Grid
} from '@mui/material';
import React from 'react';
import ArchiveButton from '../ArchiveButton';
import CreditNotesTable from './CreditNotesTable';

const PendingPaymentForm = ({ invoiceId, onSubmit, creditNotes }) => (
  <Card sx={{ padding: '1rem 1rem 1rem 1rem' }}>
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ padding: '0px', flexDirection: 'column' }}>
          {!!creditNotes?.length && (
          <Card sx={{ mb: 5 }}>
            <CreditNotesTable creditNotes={creditNotes} />
          </Card>
          )}
          <ArchiveButton invoiceId={invoiceId} onSubmit={onSubmit} />
        </Box>
      </Grid>
    </Grid>
  </Card>
);

export default PendingPaymentForm;
