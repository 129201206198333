import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Card, CardContent,
  Grid, IconButton, Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { toast } from 'react-hot-toast';
import { STATUSES } from '../../../../../../../constans/invoiceRequest';
import { useDownloadUserInvoiceMutation, useGetProofOfPaymentMutation } from '../../../../../../../store/session';
import DeclineInvoice from './DeclineInvoice';

const StyledCard = styled(Card)({
  margin: '10px 0',
  boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
});

const TitleTypography = styled(Typography)({
  fontWeight: 'bold',
});

const StatusTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
}));

const InvoiceCard = ({ invoice }) => {
  const fullStatus = STATUSES.find(stat => stat.value === invoice.payment_status);
  const [getProofOfPayment, { isLoading: FileLoading }] = useGetProofOfPaymentMutation();
  const [downloadUserInvoice] = useDownloadUserInvoiceMutation();

  const handlePreviewPoP = async (id) => {
    const toastId = toast.loading('Loading...');

    await getProofOfPayment(id).then((res) => {
      if (!res?.error) {
        toast.success('Success!', {

          id: toastId,
        });
        const fileUrl = URL.createObjectURL(res.data);
        window.open(fileUrl, '_blank', 'noreferrer');
      } else {
        toast.error('Something went wrong.', {
          id: toastId,
        });
      }
    });
  };

  const handleOpen = async( id, file_name ) => {
    const toastId = toast.loading('Downloading...');

    const res = await downloadUserInvoice(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    if (res.data instanceof Blob) {
      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(res.data);
      window.open(fileURL, '_blank');
    } else {
      // If res.data is already a URL
      window.open(res.data, '_blank');
    }

    toast.success('Success!', {
      id: toastId,
    });
  };

  return (
    <StyledCard>
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={2} sx={{ overflow: 'hidden', textWrap: 'nowrap' }}>
            <TitleTypography>{invoice?.is_proforma ? 'Proforma' : 'Invoice'}</TitleTypography>
          </Grid>
          <Grid item xs={3} sx={{ overflow: 'hidden', textWrap: 'nowrap' }}>
            <TitleTypography
              variant="body1"
              onClick={() => handleOpen(invoice.id, invoice.invoice_path)}
              sx={{
                color: 'rgb(108, 108, 241)',
                cursor: 'pointer',
                textTransform: 'capitalize',
                whiteSpace: 'break-spaces',
                wordBreak: 'break-all'
              }}
            >
              {invoice.invoice_path}
            </TitleTypography>
          </Grid>
          <Grid item xs={4}>
            <StatusTypography variant="body1" status={fullStatus}>{fullStatus?.label}</StatusTypography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => handlePreviewPoP(invoice.id)}
              disabled={!invoice.proof_of_payment_file_path}
            >
              <Tooltip title="Open Proof Of Payment">
                <OpenInNewIcon />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item xs={1} container justifyContent="flex-end">
            <DeclineInvoice requestId={invoice.id} />
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  ); };

export default InvoiceCard;
