import { RefreshRounded } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box, Card,
  InputAdornment, TextField, useMediaQuery
} from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment/moment';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import Button from '../../../../components/Button';
import EditableTable from '../../../../components/EditableTable';
import TableFilterContainer from '../../../../components/EditableTable/TableFilterContainer';
import { useDownloadUserInvoiceMutation } from '../../../../store/session';
import { useGetInvoiceManagementTablesQuery } from '../../../../store/slices/financeSlice';
import { validateDatePickerValueCopy } from '../../../../utils/setDateValue';
import { btnS } from '../../../Invoices/style';
import { YellowButton } from '../../../Logistics/style';
import { INVOICE_ORDER_TYPE_OPTIONS, INVOICE_TYPE_OPTIONS } from '../constants';
import { columns } from './columns';

const ArchivedDeclined = (props) => {
  const { type = '', invoiceProps } = props;

  const [search, setSearch] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [openInvoiceFile] = useDownloadUserInvoiceMutation();

  const maxWidth800px = useMediaQuery('(max-width:800px)');

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const filterConfig = {
    afterDivider: false,
    items: [
      {
        value: orderType,
        callback: (e) => setOrderType(e.target.value),
        closeCallback: () => setOrderType(null),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: INVOICE_ORDER_TYPE_OPTIONS,
        placeholder: 'Select Order Type'
      },
      {
        value: paymentType,
        callback: (e) => setPaymentType(e.target.value),
        closeCallback: () => setPaymentType(null),
        typeFilter: 'Select',
        icon: 'ListStatus',
        options: INVOICE_TYPE_OPTIONS,
        placeholder: 'Select Type'
      },
      {
        value: validateDatePickerValueCopy(dueDate),
        callback: (e) => setDueDate(e ? moment(e).format('DD-MM-YYYY') : ''),
        closeCallback: () => setDueDate(null),
        typeFilter: 'DatePicker',
        icon: 'CalendarMonthIcon',
        placeholder: 'Select Due Date'
      }
    ]
  };

  const handleOpenInvoice = async( id ) => {
    const toastId = toast.loading('Downloading...');

    const res = await openInvoiceFile(id);

    if (res.error) {
      toast.error('Something went wrong.', {
        id: toastId,
      });

      return;
    }

    if (res.data instanceof Blob) {
      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(res.data);
      window.open(fileURL, '_blank');
    } else {
      // If res.data is already a URL
      window.open(res.data, '_blank');
    }

    toast.success('Success!', {
      id: toastId,
    });
  };

  return (
    <>
      <Card
        sx={{
          mt: 3,
          padding: '0 1rem 1rem 1rem'
        }}
      >
        <TableFilterContainer filterConfig={filterConfig} />
      </Card>
      <Card sx={{ mt: 3, padding: '1rem' }}>
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          alignItems: 'center',
        }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
            <TextField
              size="small"
              sx={{
                mr: 6, mb: 2, minWidth: '150px', maxWidth: '150px'
              }}
              placeholder="Search..."
              onChange={debounce((e) => setSearch(e.target.value), 400)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box />
          <Box sx={{
            mb: 2, display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifySelf: 'end'
          }}
          >
            <Button
              startIcon={<RefreshRounded />}
              sx={{ ml: 2, ...(maxWidth800px && btnS), ...YellowButton }}
              title="Refresh"
              onClick={handleRefresh}
            />
          </Box>
        </Box>
        <EditableTable
          useHook={useGetInvoiceManagementTablesQuery}
          columns={columns}
          sortByType="DESC"
          sortByActive="id"
          getRowClassName={({ id }) => `row-m ${id}`}
          getRowHeight={() => 'auto'}
          getRowId={(row) => row.id}
          tableParams={{
            type,
            payment_type: paymentType,
            is_proforma: orderType,
            due_payment_date: dueDate,
            search,
            refresh,
          }}
          style={{
            '& .MuiDataGrid-columnHeaders': { borderRadius: 0 }
          }}
          getInvoiceTitle={invoiceProps?.getInvoiceTitle}
          handlePreviewPoP={invoiceProps?.handlePreviewPoP}
          handleOpenInvoice={handleOpenInvoice}
          isDecline={type === 'decline'}
        />
      </Card>
    </>
  );
};

export default ArchivedDeclined;
