import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box, Chip, Tooltip, Typography
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import moment from 'moment/moment';
import React from 'react';
import ButtonIcon from '../../../../components/ButtonIcon';
import { HeaderName, StyledDelete } from '../../../../components/EditableTable/styles';
import { DATE_FORMAT } from '../../../../constans/formats';
import { STATUSES } from '../../../../constans/invoiceRequest';
import { TextWrap } from '../../../Logistics/style';
import { linkSx } from '../../../MyProfile/styles';

export const columns = ({
  handleDownload,
  handleOpenDeleteModal,
  openProofOfPayment,
  onOpenReasonModal
}) => ([
  {
    flex: 0.1,
    field: 'id',
    minWidth: 80,
    headerName: '#',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>#</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Chip label={row?.id} variant="outlined" sx={{ fontWeight: 'bolder' }} />
    ),
  },
  {
    flex: 0.2,
    field: 'fileName',
    minWidth: 200,
    headerName: 'File',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>File</Typography>
      </Box>
    ),
    renderCell: ({ row: { fileName, id, } }) => (
      <Typography
        sx={{
          color: 'rgb(108, 108, 241)',
          cursor: 'pointer',
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
        variant="subtitle1"
        onClick={() => handleDownload({ fileName, id }, 'myInv')}
      >
        {fileName}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'payment_status',
    minWidth: 200,
    headerName: 'Payment Status',
    renderHeader: () => (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
        }}
      >
        <Typography style={HeaderName}>Payment Status</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (+row.payment_status === 5 ? (
      <Typography
        sx={{
          ...linkSx,
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}
        onClick={() => { onOpenReasonModal(row.decline_purpose); }}
      >
        {STATUSES.find(i => i.value === row.payment_status)?.label || 'Empty'}
      </Typography>
    ) : (
      <Typography
        sx={{
          textTransform: 'capitalize',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-all'
        }}

      >
        {STATUSES.find(i => i.value === row.payment_status)?.label || 'Empty'}
      </Typography>
    )),
  },
  {
    flex: 0.2,
    field: 'created_at',
    minWidth: 180,
    headerName: 'Created At',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Created At</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {moment(row.created_at).format(DATE_FORMAT)}
      </Typography>
    ),
  },
  {
    flex: 0.2,
    field: 'description',
    minWidth: 240,
    headerName: 'Description',
    renderHeader: () => (
      // eslint-disable-next-line react/jsx-filename-extension
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          fontWeight: '650'
        }}
      >
        <Typography style={{ ...(HeaderName), fontWeight: '650' }}>Description</Typography>
      </Box>
    ),
    renderCell: ({ row }) => (
      <Typography
        variant="h6"
        noWrap
        sx={TextWrap}
      >
        {row?.description}
      </Typography>
    ),
  },
  {
    width: 120,
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <ButtonIcon onClick={() => handleOpenDeleteModal(row.id)}>
          <Tooltip title="Delete">
            <StyledDelete />
          </Tooltip>
        </ButtonIcon>
        <IconButton
          disabled={!row.proof_of_payment_file_path}
          onClick={() => openProofOfPayment(row.id)}
        >
          <Tooltip title="Open Proof Of Payment">
            <OpenInNewIcon />
          </Tooltip>
        </IconButton>
      </>
    ),
  },
].filter(item => !!item));
