import { Box, Grid, Typography } from '@mui/material';
import ModalBase from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  cancel,
  headerSx, modalSx, titleSx
} from '../../../../../components/ApproveModal/styles';
import Button from '../../../../../components/Button';
import FormGroup from '../../../../../form/components/FormGroup';
import ServerAutocomplete from '../../../../../form/components/ServerAutocomplete';
import { useSearchTransactionIMQuery, useSetTransactionIMMutation } from '../../../../../store/slices/invoiceManagementSlice';

const TransactionModal = (props) => {
  const {
    open, onClose, config, refetchCallback 
  } = props;
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [setTransaction, { isLoading }] = useSetTransactionIMMutation();

  const handleSelectTransaction = (selection) => {
    const option = selection || { value: null, label: '' };
   
    setCurrentTransaction(option);
  };
 
  const onSubmit = async (id) => {
    const toastId = toast.loading('Loading...');

    const { error } = await setTransaction({ data: { transaction_id: currentTransaction?.value }, id });

    if (error) {
      toast.error(error.data.message || 'Something went wrong', {
        id: toastId,
      });

      return;
    }

    refetchCallback();
    onClose();
    
    toast.success('Successfully!', {
      id: toastId,
    });
  };

  useEffect(() => {
    if (open) {
      setCurrentTransaction(config?.transaction_id ? { value: config?.transaction_id, label: config?.transaction_id } : null);
    }
  }, [open]);

  return (
    <ModalBase
      open={open}
      onClose={onClose}
    >
      <Box sx={modalSx}>
        <Box sx={headerSx}>
          <Typography sx={titleSx}>
            Add Transaction Link
          </Typography>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Grid item xs={12}>
            <FormGroup label="Transaction (Internal ID)">
              <ServerAutocomplete
                withData
                value={currentTransaction}
                placeholder="Search Transactions"
                searchQueryFn={useSearchTransactionIMQuery}
                handleSelect={handleSelectTransaction}
                getOptionLabel={(option) => (typeof option === 'string' ? option : `${option?.label}`) || ''}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>

          </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 5 }}>
          <Button onClick={() => onSubmit(config?.id)} title="Add" disabled={isLoading} />
          <Button onClick={onClose} sx={cancel} title="Cancel" />
        </Box>
      </Box>
    </ModalBase>
  );
};

export default TransactionModal;